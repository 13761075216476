<template>
  <v-list-group :value="false" prepend-icon="mdi-translate" @click.stop>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ $t("languages") }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="{ key, keyTranslate } in languages"
      :key="key"
      @click="setLang(key, true)"
      :class="key === keyActive && 'primary'"
    >
      <v-list-icon :class="key === keyActive ? 'opacity-1' : 'opacity-0'">
        <v-icon>mdi-check</v-icon>
      </v-list-icon>
      <v-list-item-content class="language__item">
        <v-list-item-title v-text="$t(keyTranslate)" />
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { loadLanguageAsync } from "@/plugins/lang";
import { i18n } from "@/plugins/i18n";

import { settingCtrl } from "@/controllers";

export default {
  name: "LanguageMenuItem",
  data() {
    return {
      languages: [
        { key: "en", keyTranslate: "languageEN" },
        { key: "es", keyTranslate: "languageES" },
        { key: "ch", keyTranslate: "languageCH" },
      ],
    };
  },
  computed: {
    keyActive() {
      return i18n.locale || "en";
    },
  },
  methods: {
    async setLang(lang, reloadWindow = false) {
      await loadLanguageAsync(lang)
        .then(() => {
          settingCtrl.putLanguageGlobalSetting(lang).then(() => {
            if (!reloadWindow) return;
            i18n.locale = this.lang;
            location.reload();
          });
        })
        .catch(() => {
          const payload = {
            msg: i18n.t("languageNotAdded"),
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.language__item {
  padding-left: 35px !important;
}

.opacity {
  &-1 {
    opacity: 1;
  }
  &-0 {
    opacity: 0;
  }
}
</style>
